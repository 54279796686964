<template>
  <div class="row justify-content-center bg-primary-blue-wave">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" @submit.prevent="submitLogin">
            <label for="">Masukkan NIK/NIP dan Password</label>
            <base-input
              class="input-group-alternative mb-3"
              placeholder="NIK/NIP"
              addon-left-icon="fas fa-user"
              v-model="model.nip"
              autofocus
              required
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
              required
            >
            </base-input>
            <div class="text-center">
              <button
                type="submit"
                class=" btn bg-primary-blue text-white my-4"
              >
                Sign in <i class="fas fa-sign-in-alt"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import broker from "../utils/broker";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      model: {
        nip: "",
        password: ""
      }
    };
  },
  methods: {
    submitLogin() {
      let self = this;
      // self.$Progress.start();

      Swal.fire({
        title: 'Harap Tunggu!',
        html: 'Sedang memproses...',
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      });

      broker.fetch
        .post("/login", {
          nip: self.model.nip,
          password: self.model.password
        })
        .then(res => {
          Swal.close();
          if (res.data) {
            if (res.data.data) {
              let userData = res.data.data;
              if (res.data.government) {
                userData["government"] = res.data.government;
              }
              self.$store.dispatch("updateUser", userData);
            }
            if (res.data.meta.token) {
              // 	console.log("===res.data.meta.token", res.data.meta.token);
              self.$store.dispatch("updateToken", res.data.meta.token);
              window.localStorage.setItem("token", res.data.meta.token);
              broker.setToken = res.data.meta.token;
            }
            self.$store.dispatch("updatePeriods", res.data.meta.periods || []);
            self.$store.dispatch("updateScope", res.data.meta.scope);
            if (res.data.data.roles[0].name === 'Admin') {
              window.location.href = "/pemerintah/agenda-surat";
            } else {
              window.location.href = "/pemerintah/generate-token";
            }
          }
        })
        .catch(function(error) {
          Swal.close();
          let message = "Opps ! terdapat kesalahan.";
          if (error.response) {
            if (error.response.data.errors) {
              message = self.handleErrorApi(
                error.response.data.errors,
                "string"
              );
            }
            Swal.fire("Alert", message, "warning");
          }
        });
    }
  },
};
</script>
<style></style>
