<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm-4"></div>
      <div class="col-sm-4 text-right ml-auto">
        <el-button
          class="w-100 border-blue-1 col-blue"
          size="medium"
          @click="showmodal = true"
        >
          Tambah Data
        </el-button>
      </div>
    </div>
    <div class="row d-flex mb-2">
      <div class="col-sm-6">
        <h5 class="mt-2">
          {{ toTotal }} dari {{ rows }} data Token Sekolah Ditampilkan
        </h5>
      </div>

      <div class="col-sm-6 text-right ml-auto">
        <label class="mr-2 mt-2">{{ currentPage }} / {{ totalPage }}</label>
        <button
          :disabled="currentPage <= 1"
          class="btn btn-outline-primary btn-pagination btn-sm pr-3"
          @click="() => currentPage--"
        >
          <span class="ml-1"><i class="fas fa-angle-left"></i></span>
        </button>
        <button
          :disabled="currentPage >= totalPage"
          class="btn btn-outline-primary btn-pagination btn-sm pl-3"
          @click="() => currentPage++"
        >
          <span class="mr-1"><i class="fas fa-angle-right"></i></span>
        </button>
      </div>
    </div>
    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="loading"
        :fields="fields"
        :items="items"
        :no-border-collapse="noCollapse"
        :sticky-header="stickyHeader"
        class="table-kertas-kerja"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <div class="text-center text-muted my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(tipe_sekolah)="data">
          <div
            v-for="(items, index) in data.item.tipe_sekolah"
            :key="`index_${index}`"
          >
            <span
              class="badge bg-primary text-dark pl-3 pr-3"
              style="color:white !important;"
              >{{ items }}</span
            >
          </div>
        </template>
        <template #cell(token)="data">
          <span>
            {{ data.item.token }}
            <i
              @click="copyTeksInput(data.item.token)"
              class="fas fa-link cursor-pointer"
            ></i>
          </span>
        </template>

        <template #empty>
          <p class="text-center">Tidak ditemukan data yang sesuai.</p>
        </template>
        <template v-slot:cell(aksi)="data">
          <button
            class="btn btn-primary btn-sm text-white"
            @click="updateToken(data.item.id)"
          >
            Update Token
          </button>
          <button
            class="btn btn-danger  btn-sm text-white"
            @click="deleteToken(data.item.id)"
          >
            Delete Token
          </button>
        </template>
      </b-table>
    </div>
    <Modal
      :onClose="() => (showmodal = false)"
      :show="showmodal"
      :title="`Tambah Data`"
      width="50%"
    >
      <ModalDetail
        v-if="showmodal"
        :fetchData="fetchData"
        :onClose="() => (showmodal = false)"
        @closeModal="() => (showmodal = false)"
      />
    </Modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import broker from "../../../../utils/broker";
import { copyTeksInput } from "@/utils/Api";

export default {
  name: "",
  props: {
    searchSchoolType: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    Modal: () => import("@/components/ModalUI"),
    ModalDetail: () => import("./ Modal")
  },
  data() {
    return {
      fields: [
        { key: "no", sortable: false },
        { key: "tipe_sekolah", sortable: false },
        { key: "token", sortable: false },
        { key: "aksi", sortable: false }
      ],
      items: [],
      limitPage: 25,
      rows: 0,
      toTotal: 0,
      perPage: 15,
      totalPage: 0,
      currentPage: 1,
      stickyHeader: true,
      noCollapse: true,
      loading: false,
      showmodal: false
    };
  },
  watch: {
    "$store.state.selectedPeriod": function() {
      this.fetchData();
    },
    currentPage: function() {
      this.fetchData();
    },
    "$props.search": function() {
      this.currentPage = 1;
      this.rows = 0;
      this.toTotal = 0;
      this.perPage = 15;
      this.totalPage = 0;
      this.items = [];
      this.fetchData();
    },
    "$props.searchSchoolType": function() {
      this.currentPage = 1;
      this.rows = 0;
      this.perPage = 15;
      this.totalPage = 0;
      this.items = [];
      this.fetchData();
    }
  },
  methods: {
    copyTeksInput,
    async fetchData() {
      let self = this;
      if (self.loading) {
        console.log("call enpoint duplicate: STOP");
        return false;
      }
      try {

        self.loading = true;
        self.items = [];

        let params = {
          period: self.$store.state.selectedPeriod,
          limitPage: self.limitPage,
          page: self.currentPage
        };

        if (this.$props.search && this.$props.search.length > 0) {
          params["q"] = this.$props.search;
        }

        if (
          this.$props.searchSchoolType &&
          this.$props.searchSchoolType.length > 0
        ) {
          params["school_type"] = this.$props.searchSchoolType;
        }

        let resp = await broker.fetch.get(`/monitoring-token`, {
          params: params
        });

        self.rows = resp.data.meta.total;
        self.toTotal = resp.data.meta.to;
        self.perPage = resp.data.meta.per_page;
        self.totalPage = resp.data.meta.last_page;
        if (resp.data.data && Array.isArray(resp.data.data)) {
          let no = 1;

          resp.data.data.forEach(item => {
            self.items.push({
              no,
              id: item.id,
              tipe_sekolah: item.school_types,
              token: item.token
            });
            no++;
          });
        }
      } catch (error) {
        let message = "Opps ! terdapat kesalahan.";
        if (error.response) {
          if (error.response.data.errors) {
            message = this.handleErrorApi(error.response.data.errors, "string");
          }
          Swal.fire("Alert", message, "warning");
        }
      } finally {
        let self = this;
        self.loading = false;
      }
    },

    async updateToken(id) {
      try {
        await broker.fetch.put("/monitoring-token/" + id);
        await this.fetchData();
      } catch (error) {
        let message = "Opps ! terdapat kesalahan.";
        if (error.response) {
          if (error.response.data.errors) {
            message = this.handleErrorApi(error.response.data.errors, "string");
          }
          Swal.fire("Alert", message, "warning");
        }
      }
    },
    async deleteToken(id) {
      try {
        await broker.fetch.delete("/monitoring-token/" + id);
        await this.fetchData();
      } catch (error) {
        let message = "Opps ! terdapat kesalahan.";
        if (error.response) {
          if (error.response.data.errors) {
            message = this.handleErrorApi(error.response.data.errors, "string");
          }
          Swal.fire("Alert", message, "warning");
        }
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
<style lang="scss">
@import "../../../../assets/scss/custom/_rkas-table.scss";
</style>