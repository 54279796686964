<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" type="gradient-success">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <h3 class="text-primary-blue">BIODATA</h3>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="namalengkap">Nama lengkap</label>
              <input
                id="namalengkap"
                :value="userNameGetters"
                class="form-control bg-white"
                readonly
                type="text"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="provinsi">Provinsi</label>
              <input
                id="provinsi"
                :value="provinceNameGetters"
                class="form-control bg-white"
                readonly
                type="text"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="Email">Email</label>
              <input
                id="Email"
                :value="userEmailGetters"
                class="form-control bg-white"
                readonly
                type="text"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="Telepon">Telepon</label>
              <input
                id="Telepon"
                :value="userPhoneGetters"
                class="form-control bg-white"
                readonly
                type="text"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6"></div>
            <div class="form-group col-md-6">
              <a class="btn w-100 btn-outline-primary" @click="openModal()">
                Ubah Password
              </a>
            </div>
          </div>
        </div>
      </div>
      <ModalUbahPassword
        v-if="modalVisible"
        class="overlay"
        @close="modalVisible = false"
      ></ModalUbahPassword>
    </base-header>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalUbahPassword from "../../../components/Goverment/Modal/Profile/ModalUbahPassword.vue";

export default {
  name: "ProfileDetail",
  data() {
    return {
      government_id: null,
      modalVisible: false
    };
  },
  computed: {
    ...mapGetters([
      "userNameGetters",
      "govermentImageGetters",
      "provinceNameGetters",
      "userEmailGetters",
      "userPhoneGetters"
    ])
  },
  components: {
    ModalUbahPassword
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    }
  }
};
</script>
