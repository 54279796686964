<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <div class="logo-notification">
              <img
                style="object-fit: cover;"
                lazy="loading"
                v-once
                :src="require('@/assets/image/logo/logo-app.png')"
                alt="token"
              />
              GENERATE TOKEN
            </div>
            <div class="information-notification ml-auto">
              <span>Muat Ulang</span>
              <button
                type="button"
                class="btn-sync ml-1 bg-success "
                title="Sinkrokinsasi Data"
                @click="sinkrokinsasi()"
              >
                <i class="fas fa-sync-alt" title="Sinkrokinsasi Data"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-12">
          <div class="header-information d-flex align-items-center mb-3">
            <div class="header-info">
              <h1></h1>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Token generate.."
                    readonly
                    id="generateToken"
                    v-model="token"
                  />
                </div>
                <div class="col-md-2">
                  <div class="button-function">
                    <button
                      @click="generateToken()"
                      type="submit"
                      class="btn btn-generate-primary"
                      title="Token generate"
                    >
                      <i class="fas fa-sync"></i>
                    </button>
                    <button
                      type="submit"
                      @click="copyText()"
                      class="btn btn-outline-success ml-2 pl-3 pr-3"
                      title="Copy token"
                    >
                      <i class="fas fa-copy"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableGenerate ref="items" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import broker from "../../../utils/broker";
import TableGenerate from "./Table/TableGenerate.vue";
import {store} from "@/store";
import {HandlerAlertUnauthorized} from "@/utils/Api";
// import broker from "../../../utils/broker";
export default {
  data() {
    return {
      token: null,
    };
  },
  computed: {},
  components: {
    TableGenerate,
  },
  methods: {
    getToken() {
      let self = this;
      let endPoint = `${process.env.VUE_APP_API_URL}/get-token`;
      broker.fetch
        .get(endPoint)
        .then((response) => {
          if (response.data.data) {
            self.token = response.data.data.klasmonitoring_token;
          }
        })
        .catch(async (error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            if (store.state.stopRequest) {
              return {
                error: "Silahkan Login Ulang."
              };
            } else {
              await store.commit("updateState", {
                stopRequest: true
              });
              await HandlerAlertUnauthorized();
              return {
                error: "Silahkan Login Ulang."
              };
            }
          }
          let message = "Opps ! Terdapat Kesalahan.";
          // window.location.reload();
          if (error.response) {
            if (error.response.data.errors) {
              message = self.handleErrorApi(
                error.response.data.errors,
                "string"
              );
            }

            self.$Swal.fire(message);
          }
          self.$Progress.fail();
        });
    },
    generateToken() {
      let self = this;
      let endPoint = `${process.env.VUE_APP_API_URL}/generate-token`;

      broker.fetch
        .get(endPoint)
        .then((response) => {
          self.$Toast.fire({
            type: "success",
            title: "Token berhasil digenerate.",
          });
          if (response.data.data) {
            self.token = response.data.data.klasmonitoring_token;
          }
        })
        .catch(async (error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            if (store.state.stopRequest) {
              return {
                error: "Silahkan Login Ulang."
              };
            } else {
              await store.commit("updateState", {
                stopRequest: true
              });
              await HandlerAlertUnauthorized();
              return {
                error: "Silahkan Login Ulang."
              };
            }
          }
          let message = "Refresh browser anda";

          if (error.response) {
            if (error.response.data.errors) {
              message = self.handleErrorApi(
                error.response.data.errors,
                "string"
              );
            }

            this.$Swal.fire({
              type: "success",
              title: message,
              timer: 1000,
              timerProgressBar: true,
            });
          }
          self.$Progress.fail();
        });
    },
    copyText() {
      let copyText = document.getElementById("generateToken");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      this.$Swal.fire({
        type: "success",
        title: "Token telah dicopy.",
        timer: 1000,
      });
    },
    sinkrokinsasi() {
      this.$refs.items.fetchData();
    },
  },
  mounted() {
    this.getToken();
  },
};
</script>
<style lang="scss">
// .highcharts-figure,
// .highcharts-data-table table {
//   min-width: 200px;
//   max-width: 660px;
//   margin: 1em auto;
// }
@import "@/assets/scss/custom/generate-token.scss";
</style>
