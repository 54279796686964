<template>
  <div>
    <!-- tempat untuk menampilkan pesan error -->
    <div>
      <b-alert
        v-for="(item, index) in stateUnitDepartment.errors"
        :key="`notif_error_${index}`"
        dismissible
        show
        variant="danger"
      >
        <span class="alert-icon"><i class="ni ni-bell-55"></i></span>
        <span class="alert-text">{{ item.message }}</span>
      </b-alert>
    </div>

    <form
      id="formCreateUnitDepartment"
      class="form pb-2"
      @submit.prevent="submitData"
    >
      <div class="form-group">
        <label for="province_name">Provinsi</label>
        <input
          id="province_name"
          :value="$store.getters.provinceNameGetters"
          class="form-control"
          placeholder="Isi data..."
          readonly
          type="text"
        />
      </div>
      <div class="form-group">
        <label for="province_name">Kota/Kabupaten * (<small>kota/kab. tidak akan tampil jika sudah digunakan</small>)</label>
        <el-select
          v-model="stateUnitDepartment.formData.area_coverage"
          :multiple-limit="stateUnitDepartment.limitAreaCoverage"
          class="w-100"
          filterable
          multiple
          aria-autocomplete="none"
          placeholder="Pilih Kota/kabupaten"
        >
          <el-option
            v-for="item in udCitiesGetters"
            :key="`city_${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <small class="form-text text-muted">
          Kota/kabupaten bisa diisi maksimal
          {{ stateUnitDepartment.limitAreaCoverage }} data. Bertujuan untuk
          wilayah yang akan di akses.
        </small
        >
      </div>

      <div class="form-group">
        <label for="name_kacab">Nama Kepala Cabang Dinaas *</label>
        <input
          id="name_kacab"
          v-model="stateUnitDepartment.formData.name"
          class="form-control"
          placeholder="Isi data..."
          required
          type="text"
        />
        <small class="form-text text-muted">Data Kacabdin</small>
      </div>

      <div class="form-group">
        <label for="nip">NIP *</label>
        <input
          id="nip"
          v-model="stateUnitDepartment.formData.nip"
          class="form-control"
          placeholder="Isi data..."
          required
          type="text"
        />
      </div>

      <div class="form-group">
        <label for="email">Email *</label>
        <input
          id="email"
          v-model="stateUnitDepartment.formData.email"
          class="form-control"
          placeholder="Isi data..."
          required
          type="email"
        />
      </div>

      <div class="form-group">
        <label for="phone">Nomor Telpon *</label>
        <input
          id="phone"
          v-model="stateUnitDepartment.formData.phone"
          class="form-control"
          placeholder="Isi data..."
          required
          type="text"
        />
      </div>

      <div class="form-group">
        <label for="password">Password Default</label>
        <input
          id="password"
          :placeholder="stateUnitDepartment.formData.password"
          class="form-control"
          readonly
          required
          type="text"
        />
      </div>

      <button
        class="btn btn-primary pl-2 pr-2 float-right btn-sm"
        type="submit"
      >
        Simpan Data
      </button>
    </form>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "DetailUnitDepartment",
  props: ["onClose"],
  computed: {
    ...mapState({
      stateUnitDepartment: state => state.stateUnitDepartment.data,
      cities: state => state.cities.data.cities
    }),
    ...mapGetters("cities", ["citiesGetters"]),
    ...mapGetters("stateUnitDepartment", ["udCitiesGetters"]),
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions("stateUnitDepartment", ["postUnitDepartment"]),

    validasiFormData() {
      const { area_coverage } = this.stateUnitDepartment.formData;
      if (!area_coverage.length) {
        this.$message("Pilihan Kota/Kabupaten harus diisi.", "info");
        return false;
      }
      return true;
    },

    async submitData() {
      if (!this.validasiFormData()) {
        return false;
      }
      const result = await this.postUnitDepartment();
      if (result) {
        this.$emit("closeModal");
      }
    }
  }
};
</script>
